import React, { useState } from "react";
import Sketch ,{P5}from "react-p5";

function Fidenza(props) {
 
  let windowHeight = window.innerHeight
  let windowWidth = window.innerWidth

  // console.log(windowHeight)
  // console.log(windowWidth)

  const eps = Math.pow(2, -32),
    m0 = 32557,
    m1 = 19605,
    m2 = 62509,
    m3 = 22609,
    a0 = 33103,
    a1 = 63335,
    a2 = 31614,
    a3 = 5125,
    state = new Uint16Array(4),
    dv = new DataView(state.buffer);
  let _nG = null,
    _hNG = !1;
  const rnd = () => {
    const a = state[0],
      b = state[1],
      c = state[2],
      e = state[3],
      f = 0 | a0 + m0 * a,
      g = 0 | a1 + m0 * b + (m1 * a + (f >>> 16)),
      h = 0 | a2 + m0 * c + m1 * b + (m2 * a + (g >>> 16));
    state[0] = f, state[1] = g, state[2] = h, state[3] = a3 + m0 * e + (m1 * c + m2 * b) + (m3 * a + (h >>> 16));
    const i = (e << 21) + ((e >> 2 ^ c) << 5) + ((c >> 2 ^ b) >> 11);
    return eps * ((i >>> (e >> 11) | i << (31 & -(e >> 11))) >>> 0)
  },
    hash32 = (a, b = 0) => {
      const c = 16,
        e = 65535,
        f = 255;
      for (var g, j = 1540483477, m = a.length, n = b ^ m, o = 0; 4 <= m;) g = a[o] & f | (a[++o] & f) << 8 | (a[++o] & f) << 16 | (a[++o] & f) << 24, g = (g & e) * j + (((g >>> c) * j & e) << c), g ^= g >>> 24, g = (g & e) * j + (((g >>> c) * j & e) << c), n = (n & e) * j + (((n >>> c) * j & e) << c) ^ g, m -= 4, ++o;
      switch (m) {
        case 3:
          n ^= (a[o + 2] & f) << c;
        case 2:
          n ^= (a[o + 1] & f) << 8;
        case 1:
          n ^= a[o] & f, n = (n & e) * j + (((n >>> c) * j & e) << c);
      }
      return n ^= n >>> 13, n = (n & e) * j + (((n >>> 16) * j & e) << 16), n ^= n >>> 15, n >>> 0
    },
    set_seed = a => {
      _hNG = !1, _nG = null;
      const b = ~~((a.length - 2) / 2),
        c = [];
      for (let e = 0; e < b; e++) {
        const b = 2 + 2 * e;
        c.push(parseInt(a.slice(b, b + 2), 16))
      }
      const e = hash32(c, 1690382925),
        f = hash32(c, 72970470);
      dv.setUint32(0, e), dv.setUint32(4, f)
    },
    rng = (a, b) => (void 0 === b && (b = a, a = 0), rnd() * (b - a) + a),
    shffl = a => {
      for (var b, c, e = a.length, f = [...a]; e;) b = ~~(rnd() * e--), c = f[e], f[e] = f[b], f[b] = c;
      return f
    },
    gssn = (a = 0, b = 1) => {
      if (_hNG) {
        _hNG = !1;
        var c = _nG;
        return _nG = null, a + b * c
      }
      var e = 0,
        f = 0,
        g = 0;
      do e = 2 * rnd() - 1, f = 2 * rnd() - 1, g = e * e + f * f; while (1 <= g || 0 === g);
      var h = Math.sqrt(-2 * Math.log(g) / g);
      return _nG = f * h, _hNG = !0, a + b * (e * h)
    },
    nScts = 10;
  let c, ww, wh, wr, LX, RX, TY, BY, spc, z0, z1, z2, z3, z4, z5, z6, z7, sW, sH;
  const dw = 2e3,
    dh = 2400,
    V1 = 1,
    V2 = 2,
    V3 = 3,
    V4 = 4,
    V5 = 5,
    V6 = 6,
    V7 = 7;

  function setup(p5,canvasParentRef) {
    windowHeight >= 1.2 * windowWidth ? (ww = windowWidth, wh = 1.2 * windowWidth) : (wh = windowHeight, ww = windowHeight / 1.2), wr = ww / dw, c = p5.createCanvas(ww, wh).parent(canvasParentRef), p5.colorMode(p5.HSB, 360, 100, 100, 100),  p5.randomSeed(0), p5.noiseSeed(0), LX = -500, RX = 2500, TY = -.25 * dh, BY = 1.25 * dh, spc = Math.floor(10), z0 = 2, z1 = 5, z2 = 10, z3 = 20, z4 = 40, z5 = 80, z6 = 160, z7 = 320, sW = dw / nScts, sH = dh / nScts
  }

  function w(a) {
    return void 0 === a ? dw : dw * a
  }

  function h(a) {
    return void 0 === a ? dh : dh * a
  }

  function vrtx(p5, a, b) {
    p5.vertex(a * wr, b * wr)
  }

  function swght(p5, a) {
    p5.strokeWeight(a * wr)
  }

  function pi(a) {
    return Math.PI * a
  }

  function od(a) {
    return rnd() <= a
  }

  function rscl(a, b, c, e, f) {
    return e + (a - b) * ((f - e) / (c - b))
  }

  function snp(a, b) {
    let c = a % b;
    return c > .5 * b ? a + b - c : a - c
  }

  function adjFlw(a, b, c, e, f, p5) {
    for (let g = 0; g < a.length; g++) {
      const h = LX + spc * g;
      for (let i = 0; i < a[0].length; i++) {
        const j = TY + spc * i,
          k = p5.dist(b, c, h, j);
        if (k < e) {
          const b = rscl(k, 0, e, f, 0);
          a[g][i] += b
        }
      }
    }
  }

  function adjFlw2(a, b, c, e, f, p5) {
    let g = w(1);
    g = "low" === f ? w(.25) : (f = "med") ? w(.18) : w(.12);
    for (let h = 0; h < a.length; h++) {
      const f = LX + spc * h;
      for (let i = 0; i < a[0].length; i++) {
        const j = TY + spc * i,
          k = p5.dist(b, c, f, j),
          l = e ? pi(.025) : pi(-.025),
          m = l * p5.sqrt(k / g);
        a[h][i] += m
      }
    }
  }

  function flwP(a, b, c, p5) {
    const e = [];
    for (let f = LX; f < RX; f += spc) {
      const b = [];
      for (let e, g = TY; g < BY; g += spc) e = a, c && (e = angle(p5, f, g, w(.5), h(.4)) - pi(.5), d = p5.dist(f, g, w(.5), h(.5)), e += rscl(d, 0, w(1.5), 0, pi(1))), b.push(e);
      e.push(b)
    }
    let f = 0,
      g = 0;
    "none" === b ? f = 0 : "low" === b ? (f = 15, g = pi(.1)) : "med" === b ? (f = 28, g = pi(.25)) : (f = 45, g = pi(.45)), c && (f = 0);
    for (let h = 0; h < f; h++) {
      const a = rng(LX, RX),
        c = rng(TY, BY);
      if (od(.7)) {
        const b = gssn(0, g),
          f = Math.max(w(.1), Math.abs(gssn(w(.35), w(.15))));
        adjFlw(e, a, c, f, b, p5)
      } else {
        const f = od(.5);
        adjFlw2(e, a, c, f, b, p5)
      }
    }
    return e
  }

  function flwL(a, b, c, e, f, p5) {
    const g = a.length,
      h = a[0].length,
      i = w(.007),
      j = [];
    for (let k = 0; k < b.length; k++) {
      const l = [],
        m = Math.abs(gssn(c, .25 * c));
      let n = b[k][0],
        o = b[k][1];
      for (let b = 0; b < m; b++) {
        l.push([n, o]);
        const b = Math.floor((n - LX) / spc),
          c = Math.floor((o - TY) / spc);
        let j = e;
        0 <= c && c < h && 0 <= b && b < g && (j = a[b][c]), f && (j = snp(j, pi(.2))), n += i * p5.cos(j), o += i * p5.sin(j)
      }
      j.push(l)
    }
    return j
  }

  function offset(p5, a, b, c, e) {
    return [a + e * p5.cos(c), b + e * p5.sin(c)]
  }

  function angle(p5,b, c, e, f) {
    const g = p5.atan2(f - c, e - b);
    return 0 > g ? g + pi(2) : g
  }

  function pAng(p5, a, b) {
    return angle(p5, a[0], a[1], b[0], b[1])
  }

  function fatTop(p5, a, b) {
    const c = [];
    for (let e = 0; e < a.length - 1; e++) {
      let f = a[e],
        g = a[e + 1];
      const h = pAng(p5, f, g),
        i = f[0],
        j = f[1];
      c.push(offset(p5, i, j, h - pi(.5), b))
    }
    let e = a[a.length - 2],
      f = a[a.length - 1];
    const g = pAng(p5, e, f),
      h = f[0],
      i = f[1];
    return c.push(offset(p5,h, i, g - pi(.5), b)), c
  }

  function fatBot(p5, a, b) {
    const c = [];
    for (let e = 0; e < a.length - 1; e++) {
      let f = a[e],
        g = a[e + 1];
      const h = pAng(p5, f, g),
        i = f[0],
        j = f[1];
      c.push(offset(p5,i, j, h + pi(.5), b))
    }
    let e = a[a.length - 2],
      f = a[a.length - 1];
    const g = pAng(p5, e, f),
      h = f[0],
      i = f[1];
    return c.push(offset(p5, h, i, g + pi(.5), b)), c
  }

  function fat(p5, a, b) {
    const c = fatTop(p5, a, b),
      e = fatBot(p5, a, b);
    return e.reverse(), c.concat(e)
  }

  function sctrs(a, b, c) {
    const e = Math.max(0, Math.floor((a - c) / sW)),
      f = Math.min(nScts - 1, Math.floor((a + c) / sW)),
      g = Math.max(0, Math.floor((b - c) / sH)),
      h = Math.min(nScts - 1, Math.floor((b + c) / sH)),
      i = [];
    for (let j = e; j <= f; j++)
      for (let a = g; a <= h; a++) i.push([j, a]);
    return i
  }

  function cllsn(p5, a, b, c, e, f, g) {
    if (g && p5.dist(a, b, w(.5), h(.4)) <= 1.3 * c) return !0;
    const j = sctrs(a, b, c);
    for (let h = 0; h < j.length; h++) {
      let [g, i] = j[h];
      const k = e[g][i];
      for (const g of k) {
        const [e, h, i, j] = g;
        if (p5.dist(a, b, e, h) <= c + i && f !== j) return !0
      }
    }
    return !1
  }

  function cSegs(a, b, c, e, f, g, i, j, l, m, n, p5) {
    const o = [],
      p = [];
    for (let h = 0; h < nScts; h++) {
      const a = [];
      for (let b = 0; b < nScts; b++) a.push([]);
      o.push(a)
    }
    let q = w(.03);
    "low" === i && (q = w(.07));
    let r = w(.01);
    "low" === i ? r = w(.02) : "highAF" == i && (r = w(.007));
    let s = [];
    for (let k = 0; k < b.length; k++) {
      var y = b[k];
      for (let a = w(-.2); a < w(1.2); a += r) {
        const b = gssn(a, w(.005)),
          c = gssn(y, q);
        (!j || p5.dist(b, c, w(.5), h(.4)) > w(.07)) && s.push([b, c])
      }
    }
    s = shffl(s);
    const t = flwL(a, s, c, e, n, p5);
    for (let q = 0; q < t.length; q++) {
      const a = t[q];
      let b = f();
      b = m ? .65 * b : b;
      const c = g ? b + w(.03) : w(-.1),
        e = w() - c,
        i = h() - (c + w(.015)),
        r = q;
      let n = [],
        s = !1,
        u = 0;
      for (; u < a.length;) {
        const [f, g] = a[u];
        if (f >= c && f < e && g >= c && g < i && (l || !cllsn(p5, f, g, b, o, r, j))) {
          const h = 0 === n.length;
          if (!h) {
            for (const a of sctrs(f, g, b)) {
              const [c, e] = a;
              o[c][e].push([f, g, b, r])
            }
            n.push([f, g]), s = !0, u += 1
          } else {
            const f = Math.max(2, Math.floor(b / w(.001)));
            let g = !0;
            for (let h, j = 1; j < f; j++) {
              if (h = u + j, h >= a.length) {
                g = !1;
                break
              }
              const [f, k] = a[h];
              if (f < c || f >= e || k < c || k >= i || !l && cllsn(p5, f, k, b, o, r)) {
                g = !1;
                break
              }
            }
            if (g) {
              s = !0;
              for (let c = 0; c < f; c++) {
                const [c, e] = a[u];
                for (const a of sctrs(c, e, b)) {
                  const [f, g] = a;
                  o[f][g].push([c, e, b, r])
                }
                n.push([c, e]), u += 1
              }
            } else u += 1, n = []
          }
        } else s && p.push({
          points: n,
          margin: b,
          id: r
        }), s = !1, n = [], u += 1
      }
      2 <= n.length && p.push({
        points: n,
        margin: b,
        id: r
      })
    }
    return p
  }

  function lrp(a, b, c) {
    return a * (1 - c) + b * c
  }

  function crvL(a, p5) {
    if (2 > a.length) return 0;
    let b = 0;
    for (let c = 0; c < a.length - 1; c++) {
      const [e, f] = a[c], [g, h] = a[c + 1];
      b += p5.dist(e, f, g, h)
    }
    return b
  }

  function lerpCrv(p5, a, b, c) {
    const e = a[0],
      f = a[a.length - 1];
    if (0 >= b) return e;
    if (1 <= b) return f;
    if (2 === a.length) {
      const a = lrp(e[0], f[0], b),
        c = lrp(e[1], f[1], b);
      return [a, c]
    }
    const g = c * b;
    let h = 0;
    for (let e = 1; e < a.length; e++) {
      const [b, c] = a[e - 1], [f, i] = a[e], j = p5.dist(b, c, f, i), k = h + j;
      if (k > g) {
        const a = g - h,
          e = a / j,
          k = lrp(b, f, e),
          l = lrp(c, i, e);
        return [k, l]
      }
      h = k
    }
    return f
  }

  function wc(a) {
    const b = rnd();
    let c = 0;
    for (let e = 0; e < a.length - 1; e += 2) {
      const f = a[e],
        g = a[e + 1];
      if (c += g, b < c) return f
    }
    return a[a.length - 2]
  }

  function strokeSegment(p5, a, b, c, e) {
    p5.stroke(a[0], a[1], a[2]), p5.noFill(), swght(p5, w(.001));
    const f = b / w(4e-4),
      g = rng(0, 1e4);
    for (let h, i = 0; i < f; i += 1) {
      h = i / f, p5.beginShape();
      let a = .013 * (1 - c / w(1)),
        b = gssn(2 * a, a),
        j = gssn(1 - 2 * a, a);
      for (let a = b; a < j; a += .01) {
        let b = noise(4 * (a * (c / w(.25))) + g, 1.5 * h),
          f = h + .15 * (.5 - b);
        const [i, j] = e(a, f);
        vrtx(p5, i, j)
      }
      endShape()
    }
  }

  function fSeg(p5, a, b, c, e, f, g, h, i) {
    p5.fill(a[0], a[1], a[2]), b ? (p5.stroke(0, 0, 10), swght(p5, w(.001))) : (p5.stroke(a[0], a[1], a[2]), swght(p5, w(5e-4)));
    const j = [],
      k = [];
    for (let l = f; l < g; l += .01) j.push(l), k.unshift(l);
    j.push(g), p5.beginShape();
    for (const k of j) {
      const [a, b] = lerpCrv(p5, c, k, h);
      vrtx(p5, a, b)
    }
    j.reverse();
    for (const k of j) {
      const [a, b] = lerpCrv(p5, e, k, i);
      vrtx(p5, a, b)
    }
    p5.endShape(p5.CLOSE)
  }

  function pm1() {
    return z1
  }

  function pm2() {
    return wc([z0, .15, z1, .25, z2, .35, z3, .2, z4, .05])
  }

  function pm3() {
    return wc([z1, .1, z2, .2, z3, .2, z4, .3, z5, .12, z6, .08])
  }

  function pm4() {
    return wc([z1, .01, z2, .03, z3, .07, z4, .24, z5, .33, z6, .25, z7, .07])
  }

  function pm5() {
    return wc([z3, .05, z4, .2, z5, .35, z6, .3, z7, .1])
  }

  function pm6() {
    return wc([z5, .2, z6, .5, z7, .3])
  }

  function pm7() {
    return z4
  }

  function pSL(a) {
    const b = wc([w(.002), .15, w(.004), .4, w(.008), .3, w(.016), .15]);
    return a === V1 ? .5 * b : a === V2 ? .75 * b : a === V5 ? 1.25 * b : a >= V7 ? 2.5 * b : b
  }

  function pNStps(a, b) {
    return b ? 16 : Math.min(1 / a, wc([0, .2, 1, .1, 2, .15, 4, .4, 8, .12, 16, .03]))
  }
  const wht = [40, 2, 98],
    dRed = [358, 64, 86],
    red = [358, 80, 82],
    tan = [25, 40, 88],
    midTan = [25, 40, 60],
    orng = [25, 78, 90],
    pOrng = [25, 68, 93],
    pYllw = [43, 60, 99],
    yllw = [43, 90, 99],
    pnk = [11, 35, 97],
    pPnk = [12, 18, 97],
    xGrn = [125, 55, 55],
    grn = [170, 75, 65],
    pGrn = [170, 35, 80],
    ppGrn = [160, 15, 85],
    pppGrn = [160, 10, 90],
    ppYllwGrn = [125, 12, 90],
    ppBlue = [200, 15, 90],
    pBlue = [200, 35, 75],
    blue = [210, 65, 55],
    dBlue = [220, 65, 35],
    ddBlue = [225, 65, 20],
    bgrndDBlue = [225, 60, 25],
    paleIndigo = [220, 35, 75],
    lavender = [260, 14, 88],
    pBrwn = [28, 42, 39],
    brwn = [25, 45, 33],
    dBrwn = [25, 45, 23],
    ddBrwn = [25, 45, 13],
    nwsprnt = [40, 12, 88],
    bgrndNws = [40, 8, 92],
    blk = [0, 0, 10],
    pbcDefault = function () {
      return bgrndNws
    },
    pcLx = function () {
      return wc([dRed, .05, red, .03, nwsprnt, .12, orng, .02, pYllw, .06, yllw, .06, pnk, .03, grn, .04, ppGrn, .18, ddBlue, .02, dBlue, .05, blue, .05, pBlue, .03, brwn, .17, dBrwn, .09, ddBrwn, .03])
    },
    pcLxD1 = function () {
      return wc([dRed, .1, pYllw, .08, pnk, .13, grn, .2, ppGrn, .16, dBlue, .01, blue, .24, pBlue, .1, brwn, .02])
    },
    pcLxD2 = function () {
      return wc([dRed, .12, red, .1, nwsprnt, .04, orng, .05, pYllw, .1, yllw, .14, pnk, .11, grn, .13, ppGrn, .05, dBlue, .01, blue, .12, pBlue, .05])
    },
    makeLxD = function () {
      const a = [],
        b = [.6, .12, .1, .05, .03, .01, .01, .01, .01, .01, .01, .01, .01, .01, .01];
      for (let c, e = 0; 15 > e; e++) {
        if (c = null, 0 == e) c = pcLxD1();
        else if (1 === e) {
          let a = c;
          for (; a === c;) a = pcLxD2();
          c = a
        } else c = pcLx();
        a.push(c), a.push(b[e])
      }
      return function () {
        return wc(a)
      }
    },
    pbcLx = function (a) {
      return a >= V6 ? wc([ddBlue, .19, bgrndNws, .3, ppGrn, .15, pBlue, .05, pnk, .1, blue, .1, grn, .05, dRed, .05, pYllw, .01]) : a >= V4 ? wc([bgrndNws, .6, pBlue, .15, pppGrn, .1, pPnk, .1, bgrndDBlue, .05]) : wc([bgrndNws, .9, bgrndDBlue, .07, pppGrn, .03])
    },
    pcRad = function () {
      return wc([wht, .6, dRed, .05, red, .02, nwsprnt, .05, orng, .05, pYllw, .05, yllw, .03, ppGrn, .01, blue, .01, pBlue, .04, brwn, .09])
    },
    pbcRad = function () {
      return bgrndNws
    },
    pcBaked = function () {
      return wc([wht, .2, pnk, .05, pPnk, .1, xGrn, .5, ppYllwGrn, .1, pBrwn, .05])
    },
    pbcBaked = function () {
      return bgrndNws
    },
    pcCool = function () {
      return wc([nwsprnt, .13, pYllw, .01, lavender, .03, grn, .1, pppGrn, .04, ppGrn, .04, ddBlue, .11, dBlue, .15, blue, .25, pBlue, .1, brwn, .01, dBrwn, .04, ddBrwn, .02])
    },
    pbcCool = function (a) {
      return a >= V6 ? wc([bgrndNws, .5, bgrndDBlue, .3, pnk, .15, blue, .05]) : wc([bgrndNws, .8, bgrndDBlue, .12, blue, .06, pPnk, .02])
    },
    pcBlack = function () {
      return wc([bgrndNws, .15, blk, .85])
    },
    pbcBlack = function () {
      return bgrndNws
    },
    pcPolitique = function () {
      return wc([wht, .58, dRed, .02, pYllw, .2, pnk, .15, blue, .05])
    },
    pbcPolitique = function (a) {
      return a >= V6 ? wc([bgrndNws, .5, ppBlue, .5]) : wc([bgrndNws, .8, ppBlue, .2])
    },
    pcRetro = function () {
      return wc([dRed, .07, red, .03, pOrng, .05, pYllw, .02, yllw, .15, brwn, .1, dBrwn, .58])
    },
    pbcRetro = function () {
      return wc([nwsprnt, .7, pBlue, .2, wht, .1])
    },
    pcWhtMono = function () {
      return wht
    },
    pbcWhtMono = function () {
      return wc([dRed, .1, red, .1, nwsprnt, .01, orng, .1, pYllw, .04, yllw, .05, pnk, .1, grn, .1, ddBlue, .1, dBlue, .1, blue, .1, dBrwn, .02, ddBrwn, .02, blk, .09])
    },
    pcAM = function () {
      return wc([
        [260, 20, 20], .77, [240, 30, 35], .03, [300, 10, 50], .05, [180, 20, 30], .06, [130, 20, 70], .05, [5, 10, 80], .02, [5, 40, 90], .01, [40, 25, 90], .01
      ])
    },
    pbcAM = function () {
      return [260, 30, 30]
    },
    pcDarkLifestyle = function () {
      return wc([
        [0, 0, 13], .2, [0, 0, 16], .48, [0, 0, 19], .2, [0, 0, 22], .1, [0, 0, 25], .02
      ])
    },
    pbcDarkLifestyle = function () {
      return [0, 0, 10]
    },
    pcPartyGirl = function () {
      return [350, gssn(65, 4), gssn(85, 4)]
    },
    pbcPartyGirl = function () {
      return [225, 70, 20]
    },
    pcWhtOnCrm = function () {
      return wht
    },
    pbcWhtOnCrm = function () {
      return bgrndNws
    },
    pcGolfSocks = function () {
      return wc([bgrndNws, .41, [210, 72, 45], .15, [210, 72, 30], .05, [0, 40, 95], .07, [6, 20, 95], .05, [130, 50, 30], .2, [32, 30, 99], .04, [32, 30, 30], .03])
    },
    pbcGolfSocks = function () {
      return [130, 20, 50]
    },
    pcRose = function () {
      return wc([
        [150, 8, 40], .5, [160, 12, 25], .05, [350, 60, 90], .05, [350, 45, 80], .05, [350, 80, 70], .05, [6, 16, 100], .2, [15, 26, 97], .1
      ])
    },
    pbcRose = function () {
      return [150, 8, 30]
    },
    pMnCl = function (a, b, e, f) {
      let g = a();
      if (f || e < w(.015)) return g;
      for (; g === b;) g = a();
      return g
    };

  function draw(p5) {
    p5.noLoop(), p5.background(40, 10, 90);
    let a = wc([V1, .03, V2, .01, V3, .04, V5, .18, V6, .5, V7, .04, V4, .2]),
      b = wc(["none", .15, "low", .2, "med", .45, "high", .2]);
    V7 === a && (b = wc(["none", .4, "low", .3, "med", .3]));
    const c = wc([pi(.5), .1, pi(0), .1, pi(.25), .2, pi(.75), .2, pi(.05), .1, pi(.95), .1, pi(.45), .1, pi(.55), .1]),
      d = wc(["luxe", .55, "gS", .1, "rad", .09, "bkd", .05, "pltq", .05, "wM", .04, "AM", .03, "rose", .02, "blk", .02, "cool", .01, "wOC", .01, "pG", .01, "dL", .01, "lD", .01]);
    let e, f = null;
    "bkd" === d ? (e = pcBaked, f = pbcBaked) : "luxe" === d ? (e = pcLx, f = pbcLx) : "lD" === d ? (e = makeLxD(), f = pbcLx) : "cool" === d ? (e = pcCool, f = pbcCool) : "rad" === d ? (e = pcRad, f = pbcRad) : "pltq" === d ? (e = pcPolitique, f = pbcPolitique) : "retro" === d ? (e = pcRetro, f = pbcRetro) : "blk" === d ? (e = pcBlack, f = pbcBlack) : "wM" === d ? (e = pcWhtMono, f = pbcWhtMono) : "AM" === d ? (e = pcAM, f = pbcAM) : "dL" === d ? (e = pcDarkLifestyle, f = pbcDarkLifestyle) : "pG" === d ? (e = pcPartyGirl, f = pbcPartyGirl) : "wOC" === d ? (e = pcWhtOnCrm, f = pbcWhtOnCrm) : "gS" === d ? (e = pcGolfSocks, f = pbcGolfSocks) : "rose" === d ? (e = pcRose, f = pbcRose) : (e = pcLx, f = pbcLx);
    const g = a !== V7 && od(.6);
    let i = "high";
    i = a === V7 ? "highAF" : a === V6 ? "high" : a === V1 ? "highAF" : a === V2 ? wc(["high", .6, "med", .35, "low", .05]) : wc(["med", .7, "high", .2, "low", .1]);
    const j = f(a);
    p5.background(j[0], j[1], j[2]);
    let k = null;
    a === V1 ? k = pm1 : a === V2 ? k = pm2 : a === V3 ? k = pm3 : a === V5 ? k = pm4 : a === V6 ? k = pm5 : a === V7 ? k = pm6 : a === V4 && (k = pm7);
    let l = 150;
    a === V1 ? l = gssn(10, 10) : a === V2 ? l = gssn(40, 30) : a === V3 ? l = gssn(90, 50) : a === V5 ? l = gssn(150, 50) : a === V6 ? l = gssn(200, 50) : a === V7 && (l = gssn(200, 50)), l = Math.max(5, l);
    let m = [],
      n = h(.017);
    if (a === V1) {
      h(.005)
    } else if (a === V2) {
      h(.01)
    }
    "highAF" === i ? n *= .5 : "med" === i ? n *= 2.5 : "low" === i && (n *= 5);
    for (let a = h(-.1); a < h(1.101); a += n) m.push(a);
    m = shffl(m);
    const o = od(.04),
      p = od(.15) && a > V1;
    let q = !p && od(.3);
    const r = od(.025) && "wM" !== d && "blk" !== d && "dL" !== d && "pG" !== d && "wOC" !== d,
      s = !r && od(.025) && "wM" !== d && "blk" !== d && "dL" !== d && "pG" !== d && "wOC" !== d,
      t = !p && ("blk" === d || "wOC" === d || od(.2) && 70 < j[2] && a >= V5 && "pG" !== d),
      u = od(.04),
      v = flwP(c, b, o, p5),
      x = cSegs(v, m, l, c, k, g, i, o, s, r, u, p5);
    for (const b of x) {
      const d = b.margin;
      if (2 <= b.points.length) {
        p5.noStroke();
        const f = pMnCl(e, j, d, t);
        p5.fill(f[0], f[1], f[2]), t ? (p5.stroke(0, 0, 15), swght(p5, w(.001))) : p5.noStroke();
        let c = z4;
        d === z0 ? c = w(7e-4) : d === z1 ? c = w(.0019) : d === z2 ? c = w(.0038) : d === z3 ? c = w(.009) : d === z4 ? c = w(.0188) : d === z5 ? c = w(.0388) : d === z6 ? c = w(.078) : d === z7 && (c = w(.157));
        const g = fatTop(p5, b.points, c),
          i = fatBot(p5, b.points, c),
          k = fat(p5, b.points, c),
          l = crvL(p5, g),
          m = crvL(p5, i);
        if (p) strokeSegment(p5, f, c, l, function (a, b) {
          const [c, d] = lerpCrv(p5, g, a, l), [e, f] = lerpCrv(p5, i, a, m);
          return [lrp(c, e, b), lrp(d, f, b)]
        });
        else {
          p5.beginShape();
          for (const a of k) vrtx( p5, a[0], a[1]);
          p5.endShape(p5.CLOSE);
          for (const b of [!0, !1]) {
            let d = pSL(a);
            q && (d = 2 * c);
            let f = d / l;
            f = Math.floor(f * 10000) / 10000.0;
            const j = pNStps(f, q);
            if (0 < j)
              if (b) {
                for (let a = 0; a < j * f + .001 - f; a += f) {
                  const b = a;
                  let c = a + f;
                  const d = e();
                  fSeg(p5, d, t, g, i, b, c, l, m)
                }
              } else {
                for (let a = 1 - j * f; a < 1 + f; a += f) {
                  const b = a;
                  let c = a + f;
                  .99 < c && 1 > c && (c = 1.0001);
                  const d = e();
                  fSeg(p5, d, t, g, i, b, c, l, m)
                }
              }
          }
        }
      }
    }
  }

  return (
    <React.Fragment>

      <div className="container">
        <div className="row">
          <div className="col-8 offset-2 align-self-center">
            <div className="d-flex justify-content-center">
              <Sketch setup={setup} draw={(draw)} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}



export default Fidenza

